<template>
  <v-text-field
    ref="inputField"
    v-bind="{ maxlength: DEFAULT_MAX_LENGTH, ...$attrs, value, step: setStep() }"
    v-on="$listeners"
    v-model="fieldValue"
    @keydown="onKeyDown"
    @keypress="numFormatter"
    @change="validateMinMax"
  >
    <template>
      <slot name="default" />
    </template>

    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />

    <!-- Pass on all scoped slots -->
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"
      ><slot :name="slot" v-bind="scope"
    /></template>
  </v-text-field>
</template>

<script>
export default {
  name: 'GliTextField',
  props: ['value'],
  data() {
    return {
      DEFAULT_MAX_LENGTH: 200,
      numberFormatterRegex: undefined,
      zeroInserted: false,
      fieldValue: this.value
    };
  },
  mounted() {
    if (this.$attrs.numberType?.toLowerCase() === 'integer') {
      if (this.$attrs.min < 0) {
        this.numberFormatterRegex = new RegExp('^-?[0-9]', 'i');
      } else {
        this.numberFormatterRegex = new RegExp('[0-9]', 'i');
      }
    } else {
      if (this.$attrs.min < 0) {
        this.numberFormatterRegex = new RegExp('^-?[0-9,.]', 'i');
      } else {
        this.numberFormatterRegex = new RegExp('^[0-9,.]', 'i');
      }
    }
  },
  methods: {
    onKeyDown(event) {
      if (this.zeroInserted) {
        this.zeroInserted = false;
        this.fieldValue = this.fieldValue.toString().slice(0, -1) + event.key;
        event.preventDefault();
        this.$emit('input', Number(this.fieldValue));
      }

      if (event.key === ',' || event.key === '.') {
        if (this.fieldValue.toString().includes('.')) {
          event.preventDefault();
        } else {
          this.fieldValue = Number(this.fieldValue).toFixed(1);
          this.zeroInserted = true;
          event.preventDefault();
          this.$emit('input', Number(this.fieldValue));
        }
      }
    },
    numFormatter(event) {
      if (this.$attrs.type?.toLowerCase() === 'number') {
        const keyPressed = event.key;
        if (!keyPressed.match(this.numberFormatterRegex)) {
          event.preventDefault();
        }
      }
    },
    setStep() {
      if (this.$attrs.step && this.$attrs.numberType?.toLowerCase() === 'integer') {
        return Math.ceil(this.$attrs.step);
      } else if (this.$attrs.step) {
        return this.$attrs.step;
      }
      return undefined;
    },
    validateMinMax(value) {
      if (
        this.$attrs.min === undefined ||
        this.$attrs.max === undefined ||
        (this.$attrs.type?.toLowerCase() !== 'number' &&
          this.$attrs.type?.toLowerCase() !== 'integer')
      ) {
        return;
      }
      if (value === '') {
        this.fieldValue = 0;
      }
      if (Number(value) > this.$attrs.max) {
        this.fieldValue = this.$attrs.max;
      }
      if (Number(value) < this.$attrs.min) {
        this.fieldValue = this.$attrs.min;
      }
      if (this.fieldValue.toString().includes('.')) {
        this.fieldValue = Number(this.fieldValue).toFixed(1);
      }
      this.$emit('input', Number(this.fieldValue));
    }
  },
  watch: {
    value() {
      this.fieldValue = this.value;
      if (this.$attrs.type?.toLowerCase() === 'number') {
        this.$emit('input', Number(this.value));
      } else {
        this.$emit('input', this.value);
      }
    }
  }
};
</script>
