export function containsUrl(text: string): boolean {
  // text containing...
  if (
    // http://, https://, ftp://, file:// followed by any non-whitespace characters
    text.match(/(https?|ftp|file):\/{2,}\S+/gm) ||
    // www. followed by any non-whitespace characters
    text.match(/www.\S+/gm) ||
    // mailto: followed by any non-whitespace characters
    text.match(/mailto:\S+/gm) ||
    // .com, .net, .org, .gov, .hu (and other TLDs) after any non-whitespace characters
    text.match(/\S+(\.com|\.net|\.org|\.gov|\.hu|\.ro|\.sk|\.at|\.rs|\.hr|\.ua|\.cc)/gm)
  ) {
    // ...is probably an URL
    return true;
  }
  return false;
}
