import axios from 'axios';
import config from '../helpers/configProvider';

export default () => {
  const api = axios.create({
    baseURL: config.getEnv(config.enum.VUE_APP_API_SERVICE_URL) || `http://localhost:8081`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });

  return api;
};
