import config from '@/helpers/configProvider';
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  base: config.getEnv(config.enum.VUE_APP_BASE_PATH) || '/',
  routes: [
    {
      path: '/',
      name: 'RoutePlanner',
      component: () => import('@/views/RoutePlannerWrapper.vue')
    },
    {
      path: '/route-print',
      name: 'RoutePrint',
      component: () => import('@/views/RoutePrint.vue')
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
});
