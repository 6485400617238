import axios from 'axios';
import Api from './Api';

let locationSearchCancelToken = undefined;

export default {
  getVersion() {
    return Api().get('/api/version');
  },

  getConfig() {
    return Api().get('/api/config');
  },

  getStyle() {
    return Api().get('/style', { headers: { 'Cache-Control': 'No-Cache' } });
  },

  locationSearch(query) {
    // cancel the previous pending search
    if (locationSearchCancelToken) {
      locationSearchCancelToken.cancel();
    }

    locationSearchCancelToken = axios.CancelToken.source();

    return Api().get('/location/fuzzy', {
      params: { query, types: 'hnum,road,cos,admin,poi' },
      cancelToken: locationSearchCancelToken.token
    });
  },

  reverseGeocode(point) {
    return Api().get(`location/reversegeocode/${point.lng}/${point.lat}`);
  },

  routePlan(routeParams) {
    return Api().post(`/route-planner`, routeParams);
  },

  getTollRateTableValiditiesConfig() {
    return Api().get('/toll-rate-config');
  },

  sendFeedback(feedback) {
    return Api().post('/api/feedback', feedback);
  }
};
