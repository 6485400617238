import Vue from 'vue';
import Vuex from 'vuex';

import { alert } from './alert.module';
import { app, State as AppState } from './app.module';
import { feedback } from './feedback.module';
import { routePlanner } from './routePlanner.module';

Vue.use(Vuex);

export type RootState = {
  app: AppState;
};

export const store = new Vuex.Store<RootState>({
  modules: {
    alert,
    app,
    feedback,
    routePlanner
  }
});
