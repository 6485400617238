<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<style lang="scss">
html {
  overflow: auto;
}

.v-application {
  footer {
    z-index: 4;
    height: 32px;
  }
  .logo {
    width: 130px;
    background-color: white;
    padding: 4px;
    margin: 0 8px 0 0;
  }
}
</style>

<script>
export default {
  name: 'app'
};
</script>
