const config = {
  VUE_APP_X_API_KEY: '$VUE_APP_X_API_KEY',
  VUE_APP_BASE_PATH: '$VUE_APP_BASE_PATH',
  VUE_APP_API_SERVICE_URL: '$VUE_APP_API_SERVICE_URL',
  VUE_APP_I18N_LOCALE: '$VUE_APP_I18N_LOCALE',
  VUE_APP_I18N_FALLBACK_LOCALE: '$VUE_APP_I18N_FALLBACK_LOCALE',
  VUE_APP_RECAPTCHA_SITE_KEY: '$VUE_APP_RECAPTCHA_SITE_KEY'
};
export default {
  enum: {
    VUE_APP_X_API_KEY: 'VUE_APP_X_API_KEY',
    VUE_APP_BASE_PATH: 'VUE_APP_BASE_PATH',
    VUE_APP_API_SERVICE_URL: 'VUE_APP_API_SERVICE_URL',
    VUE_APP_I18N_LOCALE: 'VUE_APP_I18N_LOCALE',
    VUE_APP_I18N_FALLBACK_LOCALE: 'VUE_APP_I18N_FALLBACK_LOCALE',
    VUE_APP_RECAPTCHA_SITE_KEY: 'VUE_APP_RECAPTCHA_SITE_KEY'
  },
  getEnv: (key) => {
    const value = config[key];
    if (value.startsWith('$VUE_APP_')) {
      // value was not replaced, it seems we are not in docker
      // remove $ and get current value from process.env
      const envName = value.substr(1);
      const envValue = process.env[envName];
      if (envValue) {
        return envValue;
      } else {
        return undefined;
      }
    }
    // value was replaced in docker-entrypoint.sh
    return value;
  }
};
