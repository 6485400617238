import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '.';
import ApiService from '../services/ApiService';

type TruckEuroCategoryType = {
  value: string;
  text: string;
};

type VehicleType = {
  value: string;
  text: string;
};

export type VehicleDimensions = {
  [key: string]: {
    min: Number;
    max: Number;
  };
};

export type VehiclesDimensions = {
  [key: string]: VehicleDimensions;
};

export type State = {
  viewConfig?: {
    id: string;
    title: string;
    favicon: string;
    theme: {
      primary: string;
      secondary: string;
      accent: string;
      error: string;
      info: string;
      success: string;
      warning: string;
      background: string;
      route?: string;
      routeRestrictionForbidden?: string;
      routeRestrictionDestination?: string;
      waypoint: {
        hard?: string;
        soft?: string;
      };
      alternativeRoute?: string;
      tollRoadLayer?: string;
    };
  };
  vehicleTypes: VehicleType[];
  truckEuroCategoryTypes: TruckEuroCategoryType[];
  vehicleCategories: string[];
  vehiclesDimensions: VehiclesDimensions;
  version?: { commitHash: string; buildVersion: string };
};

const state: State = {
  viewConfig: undefined,
  vehicleTypes: [],
  truckEuroCategoryTypes: [],
  vehicleCategories: [],
  vehiclesDimensions: {},
  version: undefined
};

const actions: ActionTree<State, RootState> = {
  async fetchConfig({ commit }) {
    const { data } = await ApiService.getConfig();
    commit('setConfig', data);
  },

  async fetchVersion({ commit }) {
    const { data } = await ApiService.getVersion();
    commit('setVersion', data.version);
  }
};

const mutations: MutationTree<State> = {
  setVersion(state, payload) {
    state.version = payload;
  },

  setConfig(state, payload) {
    state.viewConfig = payload.view;
    state.vehicleTypes = payload.vehicleTypes;
    state.truckEuroCategoryTypes = payload.truckEuroCategoryTypes;
    state.vehicleCategories = payload.vehicleCategories;
    state.vehiclesDimensions = payload.vehicleDimensions;
  }
};

const getters: GetterTree<State, RootState> = {
  viewConfig: (state) => state.viewConfig
};

export const app = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
