import App from './App.vue';
import { router } from './router';
import { store } from './store';
import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import VueCookies from 'vue-cookies';
import vuetify from './plugins/vuetify';
import 'vuetify/dist/vuetify.min.css';

// import i18n for translations
import i18n from './i18n';

Vue.use(VueSweetalert2);
Vue.use(VueCookies);

// set default config: restrict to the base path
Vue.config.productionTip = false;

// register base components globally
const requireComponent = require.context('./components/base', false, /\.(vue|js)$/);
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = fileName
    .split('/')
    .pop()!
    .replace(/\.\w+$/, '');

  Vue.component(componentName, componentConfig.default || componentConfig);
});

// load client config before initiating Vue
store.dispatch('app/fetchConfig').then(async () => {
  new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: (h) => h(App) // eslint-disable-line id-length
  }).$mount('#app');
});
