import { Marker } from 'maplibre-gl';

export function createCircleMarkerHTMLElement() {
  const element = document.createElement('div');
  element.style.backgroundImage = `url(${process.env.BASE_URL + 'marker_icons/soft.svg'})`;
  element.style.backgroundSize = '100%';
  element.style.width = '15px';
  element.style.height = '15px';
  return element;
}

export function setMarkerColor(marker: Marker, color: string) {
  const markerElement = marker.getElement();
  markerElement
    .querySelectorAll('svg g[fill="' + marker._color + '"]')[0]
    .setAttribute('fill', color);
  marker._color = color;
}
