import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import VuetifyConfirm from 'vuetify-confirm';
import hu from 'vuetify/es5/locale/hu';
import Vuetify from 'vuetify/lib';
import { Ripple } from 'vuetify/lib/directives';

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
});

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#002554',
        secondary: '#FF671F',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        background: '#EFEFEF'
      },
      dark: {}
    },
    options: {
      customProperties: true
    }
  },
  lang: {
    locales: {
      hu
    },
    current: 'hu'
  },
  icons: {
    iconfont: 'mdi'
  }
});

// https://www.npmjs.com/package/vuetify-confirm
Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Igen',
  buttonFalseText: 'Nem',
  color: 'error',
  icon: 'warning',
  title: 'Warning',
  width: 350,
  property: '$confirm'
});

export default vuetify;
