import maplibregl from 'maplibre-gl';
import { WaypointTypes } from '../types';

export default class ClickableMarker extends maplibregl.Marker {
  constructor(options, id, clickCallback) {
    super(options);
    this.id = id;
    this._element.style.cursor = options.cursorStyle || 'pointer';
    this._handleClick = clickCallback;
    this._draggable = options.draggable;
    this.type = options.type || WaypointTypes.HARD;
  }

  _onMapClick(e) {
    const targetElement = e.originalEvent.target;
    const element = this._element;

    if (targetElement === element || element.contains(targetElement)) {
      if (this._handleClick) {
        this._handleClick({ id: this.id, lngLat: this._lngLat });
      }
    }
  }

  setLngLat(lngLat) {
    super.setLngLat(lngLat);
    return this;
  }

  addTo(map) {
    super.addTo(map);
    return this;
  }

  remove() {
    super.remove();
  }

  getElement() {
    return super.getElement();
  }

  addEventListener(type, listener, options) {
    super.addEventListener(type, listener, options);
    return this;
  }

  on(type, listener) {
    return super.on(type, listener);
  }

  setDraggable(isDraggable) {
    return super.setDraggable(isDraggable);
  }
}
