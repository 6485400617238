<template>
  <v-dialog max-width="500" :value="value" persistent>
    <v-card>
      <v-card-title class="background headline">
        <v-icon x-large color="primary" class="mr-3">mdi-information-outline</v-icon>
        {{ title ? title : 'Info' }}
      </v-card-title>
      <v-card-text>
        <template> <slot name="default" /> </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <gli-btn @click="$emit('closed')" color="primary"> OK </gli-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  name: 'GliInfoDialog',
  props: {
    value: Boolean,
    title: String
  }
});
</script>
