import { ActionTree, MutationTree } from 'vuex';
import { RootState } from './';

export type State = {
  icon?: string;
  type?: string;
  message?: string;
  showDialog: boolean;
};

const state = {
  icon: null,
  type: null,
  message: null,
  showDialog: false
};

const actions: ActionTree<State, RootState> = {
  success({ commit }, message) {
    commit('success', message);
  },
  error({ commit }, message) {
    commit('error', message);
  },
  openDialog({ commit }, message: string) {
    commit('openDialog', message);
  },
  closeDialog({ commit }) {
    commit('closeDialog');
  },
  clear({ commit }, message) {
    commit('success', message);
  }
};

const mutations: MutationTree<State> = {
  success(state, message) {
    state.type = 'alert-success';
    state.icon = 'mdi-check-circle-outline';
    state.message = message;
    state.showDialog = true;
  },
  error(state, message) {
    state.type = 'alert-danger';
    state.icon = 'mdi-alert-circle-outline';
    state.message = message;
    state.showDialog = true;
  },
  openDialog(state, message: string) {
    state.showDialog = true;
    state.message = message;
  },
  closeDialog(state) {
    state.showDialog = false;
    state.message = undefined;
  },
  clear(state) {
    state.type = undefined;
    state.icon = undefined;
    state.message = undefined;
  }
};

export const alert = {
  namespaced: true,
  state,
  actions,
  mutations
};
