import Vue from 'vue';
import VueI18n from 'vue-i18n';
import config from './helpers/configProvider';

Vue.use(VueI18n);

Vue.use({
  install(Vue) {
    /** Translates the value
     * @param {Object} value - Object that contains localized strings, e.g. {en:'dog',hu:'kutya'}
     * @returns {string}
     */
    Vue.prototype.$tv = function (value) {
      return value && (value[this.$i18n.locale] || value.hu || value);
    };
  }
});

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const language = window.$cookies.get('language');

export default new VueI18n({
  locale: language || config.getEnv(config.enum.VUE_APP_I18N_LOCALE) || 'hu',
  fallbackLocale: config.getEnv(config.enum.VUE_APP_I18N_FALLBACK_LOCALE) || 'hu',
  messages: loadLocaleMessages(),
  silentTranslationWarn: true
});
