import { containsUrl } from '@/helpers/url';
import ApiService from '@/services/ApiService';
import { ActionTree, MutationTree } from 'vuex';
import { RootState } from './';

type State = {
  showFeedbackDialog: boolean;
  isDataManagementAccepted: boolean;
  feedbackKey: number;
  category?: string;
  description?: string;
  email?: string;
  containsUrl: boolean;
};

const state: State = {
  showFeedbackDialog: false,
  isDataManagementAccepted: false,
  feedbackKey: 0,
  category: undefined,
  description: undefined,
  email: undefined,
  containsUrl: false
};

const actions: ActionTree<State, RootState> = {
  closeFeedbackDialog({ commit }) {
    commit('closeFeedbackDialog');
  },
  toggleDataManagementAccepted({ commit }) {
    commit('toggleDataManagementAccepted');
  },
  openFeedbackDialog({ commit }) {
    commit('openFeedbackDialog');
  },
  setCategory({ commit }, category: string) {
    commit('setCategory', category);
  },
  setDescription({ commit }, description: string) {
    if (containsUrl(description)) {
      commit('setContainsUrl', true);
    } else {
      commit('setContainsUrl', false);
      commit('setDescription', description);
    }
  },
  setEmail({ commit }, email: string) {
    commit('setEmail', email);
  },

  async sendFeedback({ commit, dispatch, rootGetters }, token: string) {
    try {
      const { data } = await ApiService.sendFeedback({
        token,
        category: state.category,
        description: state.description,
        email: state.email,
        planningParameters: rootGetters['routePlanner/printRouteParams'],
        userAgent: navigator.userAgent,
        waypoints: rootGetters['routePlanner/waypointsList'],
        routePlanner: {
          requestBody: rootGetters['routePlanner/routeParams'],
          result: rootGetters['routePlanner/routeResult']
        },
        lastAddressSearch: rootGetters['routePlanner/lastAddressSearch']
      });

      if (!data.status) {
        dispatch('alert/error', 'Visszajelzés küldése sikertelen, kérjük próbálja meg később!', {
          root: true
        });
      } else {
        dispatch('alert/success', 'Visszajelzés sikeresen elküldve! Köszönjük!', { root: true });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      dispatch('alert/error', 'Visszajelzés küldése sikertelen, kérjük próbálja meg később!', {
        root: true
      });
    }
    commit('closeFeedbackDialog');
  }
};

const mutations: MutationTree<State> = {
  closeFeedbackDialog(state) {
    state.showFeedbackDialog = false;
    state.category = undefined;
    state.description = undefined;
    state.isDataManagementAccepted = false;
    state.email = undefined;
  },
  toggleDataManagementAccepted(state) {
    state.isDataManagementAccepted = !state.isDataManagementAccepted;
  },
  openFeedbackDialog(state) {
    state.feedbackKey++;
    state.showFeedbackDialog = true;
  },
  setCategory(state, category: string) {
    state.category = category;
  },
  setContainsUrl(state, containsUrl: boolean) {
    state.containsUrl = containsUrl;
  },
  setDescription(state, description: string) {
    state.description = description;
  },
  setEmail(state, email: string) {
    state.email = email;
  }
};

export const feedback = {
  namespaced: true,
  state,
  actions,
  mutations
};
